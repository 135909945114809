import { Controller } from "stimulus";
import $ from "jquery";
export default class extends Controller {
    static targets = ["error", "input", "placeholder", "phone", "submit", "form", "success"]

    initialize() {
        this.originSubmitMessage = $(this.submitTarget).val();
    }
    clickPlaceholder() {
        var phone = this.phoneTarget;
        var input = this.inputTarget;
        this.placeholderTarget.classList.add("focused");
        this.placeholderTarget.style.display='none';
        input.style.display='block';
        $(input).val("+7");
        $(input).focus();

        $(input).blur(function() {
            if ($(input).val().length < 4) {
                $(input).val("");
                $(phone).removeClass("focused");
            }
        });
    }
    onSend() {
        $(this.errorTarget).hide();
        $(this.submitTarget).val("Секунду...");
        $(this.submitTarget).attr("disabled", "disabled");
    }
    onSendSuccess(event){
        var response = event.detail[0];
        $(this.formTarget).hide();
        this.successTarget.style.display = "block";
    }
    onSendError(event) {
        var response = event.detail[0];
        $(this.errorTarget).show().text(response.message);
        $(this.submitTarget).removeAttr("disabled");
        $(this.submitTarget).val(this.originSubmitMessage);
    }
}