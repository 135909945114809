import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        new Swiper ('.documents .swiper-container', {
            direction: 'horizontal',
            loop: false,
            watchOverflow: true,
            pagination: {
                el: '.documents .swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                820: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                },
            }
        })
    }
}