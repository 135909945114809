import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["documentsContainer", "label", 'image']
    initialize() {
        this.categoryId = this.labelTargets[0].getAttribute("data-documents-id");
        this.labelTargets[0].classList.add("active")
        this.getDocumentList();
    }

    getId(e) {
       this.categoryId = e.target.getAttribute("data-documents-id");
       this.labelTargets.forEach(target => target.classList.remove("active")) ;
       e.target.classList.add("active");
       this.getDocumentList();
    }

    getDocumentList() {
        $.ajax({
            url: `/category/${this.categoryId}/documents`,
            type: 'GET',
            success: (response) => {
                this.documentsContainerTarget.innerHTML = response;
            }
        });
    }
}